import * as THREE from 'https://threejsfundamentals.org/threejs/resources/threejs/r132/build/three.module.js';
import { AlvaARConnectorTHREE } from './alva_ar_three.js';
import { GLTFLoader } from 'https://cdn.skypack.dev/three@0.129.0/examples/jsm/loaders/GLTFLoader.js';

class ARCamIMUView {
  constructor(container, width, height) {
    this.applyPose = AlvaARConnectorTHREE.Initialize(THREE);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setClearColor(0, 0);
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.camera = new THREE.PerspectiveCamera(60, width / height, 0.01, 1000);

    this.raycaster = new THREE.Raycaster();

    this.ground = new THREE.Mesh(
      new THREE.CircleGeometry(1000, 64),
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        depthTest: true,
        // opacity: 0.1,
        opacity: 0,
        side: THREE.DoubleSide,
      })
    );

    this.ground.rotation.x = Math.PI / 2; // 90 deg
    this.ground.position.y = -10;

    this.scene = new THREE.Scene();
    this.scene.add(new THREE.AmbientLight(0x808080));
    this.scene.add(new THREE.HemisphereLight(0x404040, 0xf0f0f0, 1));
    this.scene.add(this.ground);
    this.scene.add(this.camera);

    this.scene.addEventListener('childadded', () => {
      console.log(' childadded ');
    });
    this.scene.addEventListener('added', () => {
      console.log(' added ');
    });
    this.manager = new THREE.LoadingManager();

    this.manager.onStart = function (url, itemsLoaded, itemsTotal) {
      console.log('Started loading file: ');
      const loader = document.getElementById('loader');
      if (loader) {
        loader.classList.remove('hide');
      }
    };
    this.manager.onLoad = function () {
      console.log('Loading Complete!');
      const loader = document.getElementById('loader');
      if (loader) {
        loader.classList.add('hide');
      }
    };
    this.manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      console.log('Loading file: ');
    };
    this.manager.onError = function (url) {
      console.log('There was an error loading ' + url);
    };

    this.clock = new THREE.Clock();

    container.appendChild(this.renderer.domElement);

    const render = () => {
      requestAnimationFrame(render.bind(this));

      const delta = this.clock.getDelta();
      if (this.mixers && this.mixers?.length > 0) {
        for (const mixer of this.mixers) {
          mixer.update(delta);
        }
      }

      this.renderer.render(this.scene, this.camera);
    };

    render();
  }

  updateCameraPose(pose) {
    this.applyPose(pose, this.camera.quaternion, this.camera.position);

    this.ground.position.x = this.camera.position.x;
    this.ground.position.z = this.camera.position.z;

    this.scene.children.forEach((obj) => (obj.visible = true));
  }

  lostCamera() {
    this.scene.children.forEach((obj) => (obj.visible = false));
  }

  addObjectAt(x, y, scaleProp = 10, cb) {
    const el = this.renderer.domElement;
    console.log('addObjectAt mesh ', this.scene.children);

    const coord = new THREE.Vector2(
      (x / el.offsetWidth) * 2 - 1,
      -(y / el.offsetHeight) * 2 + 1
    );

    this.raycaster.setFromCamera(coord, this.camera);

    // ищет пересечение клика с землей
    const intersections = this.raycaster.intersectObjects([this.ground]);

    if (intersections.length > 0) {
      const point = intersections[0].point;

      const filePath = window.dataItem.filePath;
      const scale = scaleProp * (window.dataItem.scale || 1);

      const itemType = window.dataItem.type.codeName;
      if (itemType === 'object') {
        const loader = new GLTFLoader(this.manager);
        const path = filePath;
        loader.load(
          path,
          (gltf) => {
            gltf.scene.scale.set(scale, scale, scale);
            gltf.scene.position.set(point.x, point.y, point.z);

            if (gltf.animations.length > 0) {
              this.mixers = [
                ...(this.mixers || []),
                new THREE.AnimationMixer(gltf.scene),
              ];
              const action = this.mixers[this.mixers.length - 1].clipAction(
                gltf.animations[0]
              );
              action.play();
            }
            gltf.scene.custom = true;

            this.scene.add(gltf.scene);
          },
          undefined,
          function (error) {
            console.error(error);
          }
        );
      } else if (itemType === 'image' || itemType === 'widget') {
        if (itemType === 'widget') {
          const widgetChildren = this.scene.children.filter(
            (child) => child.name === 'widget'
          );
          if (widgetChildren.length > 0) {
            for (let i = 0; i < widgetChildren.length; i++) {
              const widget = widgetChildren[i];
              const intersections = this.raycaster.intersectObjects([widget]);
              if (intersections.length > 0 && widget.actionUrl) {
                window.open(widget.actionUrl, '_blank');
                return;
              }
            }
          }
        }

        const loader = new THREE.TextureLoader(this.manager);
        const texture = loader.load(filePath, (tex) => {
          const material = new THREE.MeshLambertMaterial({
            map: texture,
          });
          const w = (texture.image.width / texture.image.height) * scale;
          const h = scale;
          const geometry = new THREE.PlaneGeometry(w, h);
          const mesh = new THREE.Mesh(geometry, material);

          mesh.position.set(point.x, point.y, point.z);
          mesh.custom = true;
          mesh.name = itemType;
          if (itemType === 'widget') {
            mesh.actionUrl = window.dataItem.actionUrl;
          }

          this.scene.add(mesh);
        });
      } else if (itemType === 'video') {
        const videoChildren = this.scene.children.filter(
          (child) => child.name === 'video'
        );
        if (videoChildren.length > 0) {
          videoChildren.forEach((video) => {
            const intersections = this.raycaster.intersectObjects([video]);
            if (intersections.length > 0) {
              window.toggleVideo();
            }
          });
        } else {
          const video = document.getElementById('videoContainer');
          const filePath = window.dataItem.videoUrl || window.dataItem.filePath;

          if (!video.getAttribute('src')) {
            video.setAttribute('src', filePath);
          }
          const addVideo = () => {
            const videoTexture = new THREE.VideoTexture(video);

            const w = (video.videoWidth / video.videoHeight) * scale * 10;
            const h = scale * 10;

            let videoMaterial;
            if (window.dataItem.alphaChannel) {
              videoTexture.minFilter = THREE.LinearFilter;
              videoTexture.magFilter = THREE.LinearFilter;

              // https://discourse.threejs.org/t/production-ready-green-screen-with-three-js/23113/7
              const vertexShader =
                document.getElementById('vertexShader').textContent;
              const fragmentShader =
                document.getElementById('fragmentShader').textContent;

              videoMaterial = new THREE.ShaderMaterial({
                transparent: true,
                uniforms: {
                  map: { value: videoTexture },
                  keyColor: { value: [0.0, 1.0, 0.0] },
                  similarity: { value: 0.74 },
                  smoothness: { value: 0.0 },
                },
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
              });
            } else {
              videoMaterial = new THREE.MeshBasicMaterial({
                map: videoTexture,
                side: THREE.FrontSide,
                toneMapped: false,
              });
            }

            const geometry = new THREE.PlaneGeometry(w, h);
            const mesh = new THREE.Mesh(geometry, videoMaterial);

            mesh.position.set(point.x, point.y, point.z);
            mesh.name = 'video';
            mesh.custom = true;

            this.scene.add(mesh);
            window.toggleVideo();
          };
          if (!video.videoWidth) {
            video.addEventListener('loadedmetadata', () => {
              addVideo();
            });
          } else {
            addVideo();
          }
        }
      }

      if (this.scene.children.length > 1 && cb) {
        cb();
      }
    }
  }

  reset() {
    window.toggleVideo(true);
    this.scene.children
      .filter((o) => o.custom)
      .forEach((o) => this.scene.remove(o));
  }
}

export { ARCamIMUView };

import React, { memo, useEffect } from 'react';

import { Stats } from './assets/stats.js';
import { AlvaAR } from './assets/alva_ar.js';
import { ARCamIMUView } from './assets/view.js';
import { IMU } from './assets/imu.js';
import { Camera, onFrame, resize2cover } from './assets/utils.js';
import { takeCapture, getMediaURLForTrack, toggleVisibility } from './utils.js';

import { ReactComponent as CameraBtnSvg } from '../../assets/icons/cameraBtn.svg';
import { ReactComponent as ResetBtnSvg } from '../../assets/icons/resetBtn.svg';
import { ReactComponent as LayersSvg } from '../../assets/icons/layers.svg';

import { AlvaRoot } from './components/alva-root';
import { API_URL } from '../../services/api-url';
import { SplashScreen } from '../splash-screen';

function startAlva(apiUrl, dataItem) {
  const config = {
    video: {
      facingMode: 'environment',
      aspectRatio: 16 / 9,
      width: { ideal: 1280 },
    },
    audio: false,
  };

  const $container = document.getElementById('container');
  const $view = document.createElement('div');
  const $canvas = document.createElement('canvas');
  const $overlay = document.getElementById('overlay');
  const $start = document.getElementById('start_button');
  // const $splash = document.getElementById('splash');
  const splashFadeTime = 800;

  // $splash.style.transition = `opacity ${splashFadeTime / 1000}s ease`;
  // $splash.style.opacity = 0;

  async function initScene(media, imu) {
    const $video = media.el;
    const $resetBtn = document.getElementById('remove-btn');
    const $hideBtn = document.getElementById('hide-interface-btn');
    const $cameraBtn = document.getElementById('camera-btn');

    const size = resize2cover(
      $video.videoWidth,
      $video.videoHeight,
      $container.clientWidth,
      $container.clientHeight
    );

    $canvas.width = $container.clientWidth;
    $canvas.height = $container.clientHeight;
    $video.style.width = size.width + 'px';
    $video.style.height = size.height + 'px';

    const ctx = $canvas.getContext('2d', {
      alpha: false,
      desynchronized: true,
    });
    const alva = await AlvaAR.Initialize($canvas.width, $canvas.height);
    const view = new ARCamIMUView($view, $canvas.width, $canvas.height);

    Stats.add('total');
    Stats.add('video');
    Stats.add('slam');

    $container.appendChild($canvas);
    $container.appendChild($view);

    document.body.appendChild(Stats.el);
    document.body.addEventListener(
      'click',
      (e) =>
        view.addObjectAt(e.clientX, e.clientY, undefined, () => {
          toggleVisibility($resetBtn, true);
        }),
      false
    );
    $resetBtn.hidden = true;

    $hideBtn.addEventListener(
      'click',
      (e) => {
        e.stopPropagation();
        toggleVisibility($resetBtn);
        toggleVisibility($cameraBtn);
      },
      false
    );

    $resetBtn.addEventListener(
      'click',
      (e) => {
        e.stopPropagation();
        view.reset();
        toggleVisibility($resetBtn);
      },
      false
    );

    $cameraBtn.addEventListener(
      'click',
      (e) => {
        e.stopPropagation();
        takeCapture();
      },
      false
    );

    window.toggleVideo = (reset = false) => {
      const video = document.getElementById('videoContainer');
      if (!video.getAttribute('src')) return;

      if (reset) {
        video.pause();
        window.videoStarted = false;
        return;
      }

      if (window.videoStarted) {
        video.pause();
        window.videoStarted = false;
      } else {
        video.play();
        window.videoStarted = true;
      }
    };

    onFrame(() => {
      try {
        Stats.next();
        Stats.start('total');

        ctx.clearRect(0, 0, $canvas.width, $canvas.height);

        if (!document['hidden']) {
          Stats.start('video');
          ctx.drawImage(
            $video,
            0,
            0,
            $video.videoWidth,
            $video.videoHeight,
            size.x,
            size.y,
            size.width,
            size.height
          );
          const frame = ctx.getImageData(0, 0, $canvas.width, $canvas.height);
          Stats.stop('video');

          Stats.start('slam');
          const pose = alva.findCameraPoseWithIMU(
            frame,
            imu.orientation,
            imu.motion
          );
          Stats.stop('slam');

          if (pose) {
            view.updateCameraPose(pose);
          } else {
            view.lostCamera();

            const dots = alva.getFramePoints();

            for (const p of dots) {
              ctx.fillStyle = 'white';
              ctx.fillRect(p.x, p.y, 2, 2);
            }
          }
        }

        Stats.stop('total');
        // Stats.render();

        return true;
      } catch (e) {
        console.log(' e ', e);
        alert('sensors error onframe');
        return false;
      }
    }, 30);
  }

  if (dataItem) {
    window.dataItem = dataItem;
    if (window.dataItem.type.codeName === 'video') {
      getMediaURLForTrack(dataItem.filePath);
    }
  }

  setTimeout(() => {
    // $splash.remove();
    $start.addEventListener('click', async () => {
      try {
        $overlay.setAttribute('style', 'display: none');

        const imu = await IMU.Initialize();
        const cam = await Camera.Initialize(config);

        console.log(' imu ', imu);
        console.log(' cam ', cam);

        await initScene(cam, imu);
      } catch (error) {
        console.log('init error ', error);
        alert('Error: ' + error);
      }
    });
  }, splashFadeTime);
}

export const AlvaSpace = memo((props) => {
  const { data } = props;

  useEffect(() => {
    startAlva(API_URL, data);
    // eslint-disable-next-line
  }, []);

  return (
    <AlvaRoot>
      <div id="container" />
      <div id="loader" className="loader-container hide">
        <div className="loader" />
      </div>

      <SplashScreen
        text={
          <>
            Разрешите доступ к <br />
            камере и датчику движения.
          </>
        }
      />

      <div className="container container-space">
        <CameraBtnSvg class="camera-btn" id="camera-btn" />
        <ResetBtnSvg
          style={{ display: 'none' }}
          class="remove-btn"
          id="remove-btn"
        />
        <LayersSvg class="base-icon camera-icon" id="hide-interface-btn" />
      </div>

      <video
        crossOrigin="anonymous"
        id="videoContainer"
        loop
        playsInline
        src=""
        style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
      />
    </AlvaRoot>
  );
});

export function takeCapture() {
  window.toggleVideo(true);
  const container = document.querySelectorAll('#container canvas');
  const camera = container[0];
  const sceneCanvas = container[1];

  const canvas = document.createElement('canvas');
  canvas.width = camera.width;
  canvas.height = camera.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(camera, 0, 0, canvas.width, canvas.height);
  ctx.drawImage(sceneCanvas, 0, 0, canvas.width, canvas.height);

  const url = canvas.toDataURL('image/png');
  const link = document.createElement('a');

  link.download = window.dataItem?.name || 'screen';
  link.href = url;
  link.click();
}

export function getMediaURLForTrack(url) {
  fetch(url, { method: 'GET' }).then((response) => {
    if (!window.dataItem) return;
    window.dataItem.videoUrl = response.url;
  });
}

export function toggleVisibility(el, forceShow = false, forceHide = false) {
  if (el.hidden || forceShow) {
    el.setAttribute('style', 'display: block');
    el.hidden = false;
    return;
  }

  if (!el.hidden || forceHide) {
    el.setAttribute('style', 'display: none');
    el.hidden = true;
    return;
  }
}
